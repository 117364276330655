<template>
    <div class="text-center max-auto">
        <div class="mb-5 label">
            上傳尺寸限制：w:{{ imageLimit.width.join(",") }}*h:{{
                imageLimit.height.join(",")
            }}
        </div>
        <el-upload
            action="#"
            :limit="1"
            :auto-upload="false"
            :on-change="changeFiled"
            accept=".jpg, .jpeg, .png"
            list-type="picture"
            :file-list="fileList"
        >
            <button
                v-if="!imageData.url"
                class="orange-btn-outline-800 md:text-sm text-xs mr-1 w-[113px] h-[35px]"
            >
                上傳圖片
            </button>
            <div slot="file"></div>
        </el-upload>
        <div v-if="imageData.url" class="display-img">
            <div class="cursor-pointer error" @click="handleRemove()">
                <i class="fas fa-times"></i>
            </div>
            <img class="w-[200px] rounded-full mx-auto" :src="imageData.url" />
        </div>
        <div class="mt-2">
            <el-button
                v-loading="loading"
                :disabled="$isEmpty(imageData.url)"
                type="primary"
                @click.prevent="addAvatar"
                >新增</el-button
            >
        </div>
    </div>
</template>

<script >
import { ref, reactive, del, set, computed, onActivated, onMounted, watch, getCurrentInstance, defineExpose, onBeforeUnmount, nextTick } from "@vue/composition-api";
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const emit = __ctx.emit;

  // 取得全域 this 方法 ex: this.$api, this.$message
  const {
    proxy
  } = getCurrentInstance();
  const loading = ref(false);

  // 預設 image 暫存物件
  const defaultImg = {
    url: "",
    file: null,
    base64: ""
  };

  // 畫面呈現 image 暫存物件
  const imageData = ref({
    ...defaultImg
  });
  const fileList = ref([]);
  const imageLimit = ref({
    width: [512, 512 * 2],
    height: [512, 512 * 2]
  });

  // 刪除圖片
  function handleRemove() {
    imageData.value = {
      ...defaultImg
    };
    fileList.value = [];
  }

  // 更新圖片
  function changeFiled(file, fileList) {
    if (file.size / (1024 * 1024) > 5) {
      fileList.value = [];
      alert("檔案大小超出5mb");
      return;
    }
    fileList.value = fileList.slice(-3);
    imageData.value.url = file.url;
    imageData.value.file = file.raw;
    const reader = new FileReader();
    // 讀取上傳檔案
    reader.readAsDataURL(file.raw);
    // 將上傳檔案轉為base64
    reader.onload = () => {
      imageData.value.base64 = reader.result;
      // 確認 圖片 size
      checkImageLimit(imageData.value.base64, imageLimit.value);
    };
  }
  // 確認圖片寬高
  function checkImageLimit(base64, limit) {
    const img = new Image();
    img.src = base64;
    img.onload = () => {
      if (!limit.height.includes(img.height) || !limit.width.includes(img.width)) {
        fileList.value = [];
        imageData.value = {
          ...defaultImg
        };
        alert("圖片不符合寬高限制");
      }
    };
  }
  async function addAvatar() {
    const result = new FormData();
    if (imageData.value.file !== null) {
      result.append("image", imageData.value.file);
    }
    loading.value = true;
    try {
      const {
        data
      } = await proxy.$api.AddNotifyAvatarApi(result);
      handleRemove();
      emit("uploadSuccess", data.url);
      proxy.$message({
        type: "success",
        message: "上傳通知圖像成功"
      });
    } catch (err) {
      proxy.$message({
        type: "error",
        message: "上傳通知圖像失敗"
      });
    } finally {
      loading.value = false;
    }
  }
  return {
    loading,
    imageData,
    fileList,
    imageLimit,
    handleRemove,
    changeFiled,
    addAvatar
  };
};
export default __sfc_main;
</script>

<style lang="scss" scoped>
::v-deep .el-upload-list {
    display: none;
}
</style>
