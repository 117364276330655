<template>
    <div class="grid grid-cols-12 gap-4">
        <div class="col-span-3">
            <div class="p-5 bg-white rounded-lg shadow-lg">
                <ul class="list-none">
                    <li
                        v-for="(item, index) in tabs"
                        :key="index"
                        class="py-3 text-center cursor-pointer"
                        :class="
                            item.value === choseRule
                                ? 'bg-red-500 bg-opacity-20 rounded-lg'
                                : ''
                        "
                        @click="changeTab(item.value)"
                    >
                        {{ item.label }}
                    </li>
                </ul>
            </div>
        </div>
        <div class="col-span-9 p-5 bg-white rounded-lg">
            <SearchForm
                ref="searchFormRefDom"
                :choseRule="choseRule"
                :loading="loading"
                @onSearch="filterSearch"
                @onReset="resetSearchData"
            />
            <div class="py-5 border-t border-b">
                <h2 class="my-5 text-xl text-center">頭像列表</h2>
                <div class="flex items-center">
                    <AddNotifyAvatar
                        class="flex-1 border-r"
                        @uploadSuccess="handleAddNotifyAvatar"
                    />
                    <AvatarList
                        ref="avatarListRef"
                        class="flex-1 ml-2"
                        :chooseAvatar.sync="form.avatar"
                    />
                </div>
            </div>
            <h1 class="my-5 text-xl text-center">發送系統通知訊息</h1>
            <el-form ref="formRefDom" :model="form" :rules="rules">
                <div class="grid grid-cols-2 gap-4">
                    <el-form-item prop="type">
                        <label class="block" for="notifyType"
                            >通知發送選項</label
                        >
                        <el-select
                            id="notifyType"
                            v-model="form.notifyType"
                            class="w-full"
                        >
                            <el-option
                                v-for="option in notifyOptions"
                                :key="option.value"
                                :value="option.value"
                                :label="option.label"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="avatar">
                        <label class="block" for="notifyAvatar">通知圖示</label>
                        <el-input
                            id="notifyAvatar"
                            v-model="form.avatar"
                            :disabled="true"
                            type="text"
                        ></el-input>
                    </el-form-item>
                    <el-form-item prop="type">
                        <label class="block" for="notifyType">通知類型</label>
                        <el-select
                            id="notifyType"
                            v-model="form.type"
                            class="w-full"
                        >
                            <el-option
                                v-for="option in options"
                                :key="option.value"
                                :value="option.value"
                                :label="option.label"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="title">
                        <label class="block" for="notifyTitle">通知標題</label>
                        <el-input
                            id="notifyTitle"
                            v-model="form.title"
                            type="text"
                            maxlength="20"
                            :show-word-limit="true"
                        ></el-input>
                    </el-form-item>
                </div>
                <el-form-item prop="link">
                    <label class="block" for="notifyAvatar">通知連結</label>
                    <el-input v-model="form.link" type="text"></el-input>
                </el-form-item>
                <el-form-item prop="message">
                    <label class="block" for="notifyMessage">通知訊息</label>
                    <el-input
                        id="notifyMessage"
                        v-model="form.message"
                        :show-word-limit="true"
                        type="textarea"
                        rows="10"
                        maxlength="30"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div class="flex justify-center mt-10">
                <el-button
                    v-loading="loading"
                    v-permission="['create', 'update']"
                    type="primary"
                    native-type="submit"
                    :disabled="total <= 0"
                    @click.prevent="onSubmit('form')"
                >
                    發送
                </el-button>
            </div>
            <div class="mt-5 mb-10 text-center">
                發送總數：<strong class="font-bold">{{ total }}</strong>
                <div class="el-form-item__error">發送總數不可為0</div>
            </div>
        </div>
    </div>
</template>

<script >
import { ref, reactive, del, set, computed, onActivated, onMounted, watch, getCurrentInstance, defineExpose, onBeforeUnmount, nextTick } from "@vue/composition-api";
import firebase from "firebase/app";
import { firebaseConnectRef } from "@/plugins/firebase";
import SearchForm from "./components/SearchForm.vue";
import AddNotifyAvatar from "./components/AddNotifyAvatar.vue";
import AvatarList from "./components/NotifyAvatarList.vue";
import { isEmpty } from "@/service/anyService";
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const loading = ref(false);
  // 取得全域 this 方法 ex: this.$api, this.$message
  const {
    proxy
  } = getCurrentInstance();
  // 預設表單資料
  const defaultForm = () => ({
    type: "",
    title: "",
    message: "",
    avatar: "",
    link: ""
  });
  // 使用者總數
  const total = ref(0);
  // 表單資料
  const form = ref(defaultForm());
  // 表單驗證規則
  const rules = ref({
    title: [{
      required: true,
      message: "請輸入通知標題",
      trigger: "blur"
    }],
    message: [{
      required: true,
      message: "請輸入通知內容",
      trigger: "blur"
    }],
    type: [{
      required: true,
      message: "請選擇通知類型",
      trigger: "blur"
    }]
  });
  // 判斷選擇會員或服務商還是沒選
  const choseRule = ref(0);
  // 搜尋條件 dom
  const searchFormRefDom = ref(null);
  // 表單 dom
  const formRefDom = ref(null);
  const filterData = ref({});
  // 通知選項
  const options = ref([{
    label: "系統",
    value: "system"
  }, {
    label: "訂單",
    value: "order"
  }, {
    label: "交易",
    value: "transaction"
  }, {
    label: "其它",
    value: "other"
  }]);
  // 通知發送選項
  const notifyOptions = ref([{
    label: "全發",
    value: "all"
  }, {
    label: "小鈴鐺",
    value: "bell"
  }, {
    label: "FCM",
    value: "fcm"
  }]);
  // 轉發對象選擇
  const tabs = ref([{
    label: "全部",
    value: 0
  }, {
    label: "轉發服務商",
    value: 1
  }, {
    label: "轉發會員",
    value: 2
  }]);
  /**
   * 選擇 tab 事件
   * @param { type Array(陣列) } val 使用者身份狀態
   */
  function changeTab(val) {
    choseRule.value = val;
    total.value = 0;
    filterData.value = {};
    searchFormRefDom.value.resetData(false);
  }
  // 發送訊息按鈕
  async function onSubmit() {
    loading.value = true;
    try {
      // 表單驗證
      await formRefDom.value.validate();
      if (isEmpty(form.value.link)) {
        delete form.value.link;
      }
      // 發送訊息 api
      console.log("send notify =>", form.value);
      await send(form.value);
    } catch (err) {
      loading.value = false;
      console.log(err);
      proxy.$message({
        type: "error",
        message: "表單驗證失敗"
      });
    } finally {
      loading.value = false;
    }
  }
  /**
   * 發送系統通知
   * @param { type Object(物件) } formData 通知資料
   * @example {
   * title: 測試標題 { type String (字串)} 通知標題
   * message: 測試內容 { type String (字串)} 通知內容
   * type: system { type String (字串)} 通知類別
   * }
   */
  async function send(formData) {
    if (total.value <= 0) {
      return;
    }
    try {
      await proxy.$api.SendSystemNotifyApi({
        ...formData,
        page: 1,
        filterData: filterData.value
      });
      resetSearchData();
      await avatarListRef.value.getDatas();
      searchFormRefDom.value.resetData();
      proxy.$message({
        type: "success",
        message: "發送系統通知成功"
      });
    } catch (err) {
      console.log(err);
      proxy.$message({
        type: "error",
        message: "發送系統通知失敗"
      });
    } finally {
      loading.value = false;
    }
  }
  /**
   * 搜尋使用者 api
   */
  async function filterSearch(val) {
    filterData.value = val;
    loading.value = true;
    try {
      const {
        data
      } = await proxy.$api.getUsersUseNestJSApi(val);
      total.value = data.total;
    } catch (err) {
      console.log(err);
      proxy.$message({
        type: "error",
        message: "搜尋失敗"
      });
    } finally {
      loading.value = false;
    }
  }
  // 重置搜尋資料
  async function resetSearchData() {
    // 預設搜尋資料
    form.value = defaultForm();
    await avatarListRef.value.getDatas();
    filterData.value = {};
    total.value = 0;
  }
  const avatarListRef = ref(null);

  /**
   * 通知頭像上傳成功 callback
   */
  async function handleAddNotifyAvatar(val) {
    form.value.avatar = val;
    await avatarListRef.value.getDatas(true);
  }
  onMounted(async () => {
    if (avatarListRef.value) {
      await avatarListRef.value.getDatas();
    }
  });
  return {
    loading,
    total,
    form,
    rules,
    choseRule,
    searchFormRefDom,
    formRefDom,
    options,
    notifyOptions,
    tabs,
    changeTab,
    onSubmit,
    filterSearch,
    resetSearchData,
    avatarListRef,
    handleAddNotifyAvatar
  };
};
__sfc_main.components = Object.assign({
  SearchForm,
  AddNotifyAvatar,
  AvatarList
}, __sfc_main.components);
export default __sfc_main;
</script>
