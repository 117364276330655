<template>
    <div>
        <ul
            ref="avatarListRef"
            class="grid grid-cols-4 gap-4 h-[200px] overflow-y-auto"
        >
            <li
                v-for="(data, index) in datas"
                :key="index"
                class="mx-auto mr-2 text-center"
            >
                <img
                    :class="
                        chooseAvatar === data ? 'border-2 border-red-500' : ''
                    "
                    class="w-[50px] h-[50px] rounded-full cursor-pointer"
                    :src="data"
                    @click="
                        chooseAvatar = data;
                        emit('update:chooseAvatar', chooseAvatar);
                    "
                />
                <input
                    v-model="chooseAvatar"
                    :value="data"
                    type="radio"
                    @change="emit('update:chooseAvatar', data)"
                />
            </li>
        </ul>
    </div>
</template>

<script >
import { ref, reactive, del, set, computed, onActivated, onMounted, watch, getCurrentInstance, defineExpose, onBeforeUnmount, nextTick } from "@vue/composition-api";
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const emit = __ctx.emit;
  const chooseAvatar = ref(null);
  const avatarListRef = ref(null);

  // 取得全域 this 方法 ex: this.$api, this.$message
  const {
    proxy
  } = getCurrentInstance();
  const datas = ref([]);
  async function getDatas(isAdd = false) {
    try {
      const {
        data: {
          urls
        }
      } = await proxy.$api.GetNotifyAvatarListApi();
      datas.value = urls;
      // 判斷是新增圖片時觸發
      if (isAdd) {
        chooseAvatar.value = datas.value[datas.value.length - 1];
        // 上傳新圖片後 將捲軸往下滾到底
        avatarListRef.value.scrollTop = avatarListRef.value.scrollHeight;
        emit("update:chooseAvatar", chooseAvatar.value);
        return;
      }
      chooseAvatar.value = datas.value[0];
      emit("update:chooseAvatar", chooseAvatar.value);
    } catch (err) {
      console.log("GetNotifyAvatarListApi =>", err);
    }
  }
  return Object.assign({
    emit,
    chooseAvatar,
    avatarListRef,
    datas
  }, {
    getDatas
  });
};
export default __sfc_main;
</script>
